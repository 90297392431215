
import Vue, { PropType } from "vue";

import http from "@/http";

import { User, Snackbar, Member } from "@/interfaces";

import MyOverview from "@/components/member/MyOverview.vue";

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true
    }
  },
  data() {
    return {
      email: ""
    };
  },
  methods: {
    async click() {
      const url = `/members/${this.member.id}/users`;

      await http
        .post<User>(url, {
          email: this.email
        })
        .catch(error => {
          const { data, status } = error.response;
          if (status === 422) {
            const snackbar: Snackbar = {
              show: true,
              content: data.errors.email[0],
              color: "error"
            };

            this.$store.commit("setSnackbar", snackbar);
          }
        });

      this.$router.push(`/member/${this.member.id}`);
    }
  }
});
